import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-video-reconocimiento',
  standalone: true,
  imports: [],
  templateUrl: './video-reconocimiento.component.html',
  styleUrl: './video-reconocimiento.component.css'
})
export class VideoReconocimientoComponent implements AfterViewInit, OnInit {
  @Input() prediccion!: string
  @ViewChild('webcam') videoRef!: ElementRef<HTMLVideoElement>;
  @Output() videoElement = new EventEmitter<ElementRef<HTMLVideoElement>>()
  constructor(){
  }

  ngAfterViewInit() {
    console.log('Video Element:', this.videoRef);
    this.videoElement.emit(this.videoRef);
    this.setupCamera();
  }
  ngOnInit(){
  }
  setupCamera(){
    const video: HTMLVideoElement = this.videoRef.nativeElement;
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({
          video: {width: 300, height:250},
          audio: false
      }).then(stream=>{
          console.log(stream);
          video.srcObject = stream;
          video.play();
      }).catch((err) => {
        console.log(err.message);
        console.error(`${err.name}: ${err.message}`);
      });
    } else {
      console.error('getUserMedia no está disponible en este navegador.');
    }
  }


}
